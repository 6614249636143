import React, { useContext } from "react";

import { ChatSource } from "apis/chat";

type Context = {
  source: "genie" | "chat";
};

export const ChatSourceContext = React.createContext<Context | undefined>(
  undefined
);

type Props = {
  source: ChatSource;
  children: React.ReactNode;
};

export const ChatSourceProvider = ({ source, children }: Props) => {
  return (
    <ChatSourceContext.Provider value={{ source }}>
      {children}
    </ChatSourceContext.Provider>
  );
};

export const useChatSource = () => {
  const context = useContext(ChatSourceContext);
  if (context === undefined) {
    throw new Error("useChatSource must be used within a ChatSourceProvider");
  }
  return context;
};
