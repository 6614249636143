import { isAfter, isToday, isYesterday, subDays } from "date-fns";

import { useEffect, useState } from "react";

import { css } from "@emotion/react";

import { useRouter } from "next/router";

import { Button } from "components/shared/library/Button";
import { Card } from "components/shared/library/Card";
import { Typography } from "components/shared/library/Typography";

import { useChatSource } from "contexts/ChatSourceContext";

import { Chat, useChatHistory } from "apis/chat";

import { getChatOverviewUrl } from "utils/urls";

import {
  SHIMMER_KEYFRAME,
  SHIMMER_TRANSITION_DURATION,
  SHIMMER_TRANSITION_TIMING_FUNCTION,
} from "constants/animations";
import { black, uiGray, white } from "constants/colors";

import { ChatButton } from "./ChatButton";

const style = {
  card: css({
    borderRadius: 16,
    background: white,
    padding: 24,
    gap: 16,
    display: "flex",
    flexDirection: "column",
  }),
  sidePanel: css({
    margin: 16,
  }),
  earningsHistory: css({
    display: "flex",
    flexDirection: "column",
    gap: 16,
  }),
  shimmer: css({
    animation: `${SHIMMER_KEYFRAME} ${SHIMMER_TRANSITION_DURATION} infinite ${SHIMMER_TRANSITION_TIMING_FUNCTION}`,
    background: `linear-gradient(to right, #F1EFEF 4%, ${uiGray.pageBackground} 25%, #F1EFEF 66%)`,
    backgroundSize: "1000px 100%",
    width: "-webkit-fill-available",
    height: 24,
  }),
};

type Props = {
  isSidePanel?: boolean;
};

export const ChatHistory = ({ isSidePanel = false }: Props) => {
  const [todayChats, setTodayChats] = useState<Chat[]>([]);
  const [yesterdayChats, setYesterdayChats] = useState<Chat[]>([]);
  const [lastWeekChats, setLastWeekChats] = useState<Chat[]>([]);
  const [olderChats, setOlderChats] = useState<Chat[]>([]);

  const { source } = useChatSource();

  const {
    chats,
    loadMoreChats,
    isFinalPage,
    loadingMore,
    isLoaded,
    deleteChatFromHistory,
  } = useChatHistory(source);
  const router = useRouter();
  useEffect(() => {
    const todays: Chat[] = [];
    const yesterdays: Chat[] = [];
    const lastWeeks: Chat[] = [];
    const olders: Chat[] = [];
    chats.forEach((chat) => {
      if (isToday(new Date(chat.createdAt))) {
        todays.push(chat);
        return;
      }
      if (isYesterday(new Date(chat.createdAt))) {
        yesterdays.push(chat);
        return;
      }
      const weekAgo = subDays(new Date(), 7);
      if (isAfter(new Date(chat.createdAt), weekAgo)) {
        lastWeeks.push(chat);
        return;
      }
      olders.push(chat);
    });
    setTodayChats(todays);
    setYesterdayChats(yesterdays);
    setLastWeekChats(lastWeeks);
    setOlderChats(olders);
  }, [chats]);

  return (
    <div css={[style.card, isSidePanel ? style.sidePanel : undefined]}>
      <Typography size="M" weight="bold" color={black}>
        Your history
      </Typography>
      <div css={style.earningsHistory}>
        {isLoaded ? (
          <>
            {chats.length == 0 && (
              <Typography size="M" color={uiGray[70]}>
                You have no chat history yet.
              </Typography>
            )}
            {todayChats.length >= 1 && (
              <>
                <Typography weight="bold" color={uiGray[50]} size="XS">
                  Today
                </Typography>
                {todayChats.map((chat) => (
                  <ChatButton
                    key={chat.id}
                    chat={chat}
                    onDelete={deleteChatFromHistory}
                  />
                ))}
              </>
            )}
            {yesterdayChats.length >= 1 && (
              <>
                <Typography weight="bold" color={uiGray[50]} size="XS">
                  Yesterday
                </Typography>
                {yesterdayChats.map((chat) => (
                  <ChatButton
                    key={chat.id}
                    chat={chat}
                    onDelete={deleteChatFromHistory}
                  />
                ))}
              </>
            )}
            {lastWeekChats.length >= 1 && (
              <>
                <Typography weight="bold" color={uiGray[50]} size="XS">
                  Last week
                </Typography>
                {lastWeekChats.map((chat) => (
                  <ChatButton
                    key={chat.id}
                    chat={chat}
                    onDelete={deleteChatFromHistory}
                  />
                ))}
              </>
            )}
            {olderChats.length >= 1 && (
              <>
                <Typography weight="bold" color={uiGray[50]} size="XS">
                  Older
                </Typography>
                {olderChats.map((chat) => (
                  <ChatButton
                    key={chat.id}
                    chat={chat}
                    onDelete={deleteChatFromHistory}
                  />
                ))}
              </>
            )}

            {!isFinalPage && (
              <Button
                size="small"
                background="ghost"
                onClick={
                  isSidePanel
                    ? () => router.push(getChatOverviewUrl())
                    : loadMoreChats
                }
                isLoading={!loadingMore}
              >
                {isSidePanel ? "View all history" : "show more"}
              </Button>
            )}
          </>
        ) : (
          <>
            <Card customCss={style.shimmer} />
            <Card customCss={style.shimmer} />
            <Card customCss={style.shimmer} />
            <Card customCss={style.shimmer} />
            <Card customCss={style.shimmer} />
          </>
        )}
      </div>
    </div>
  );
};
