import { useRouter } from "next/router";

import { Modal } from "components/shared/library/Modal";

import { useChatSource } from "contexts/ChatSourceContext";
import { useAlert } from "contexts/alertContext";

import { getChatOverviewUrl, getGenieOverviewUrl } from "utils/urls";

type Props = {
  isModalOpen: boolean;
  onDelete: () => Promise<{ error: boolean }>;
  onClose: () => void;
  chatId?: string;
};

export const DeleteModal = ({
  onDelete,
  isModalOpen,
  onClose,
  chatId: chatIdProp,
}: Props) => {
  const { createAlert } = useAlert();
  const router = useRouter();
  const { source } = useChatSource();
  const { chatId } = router.query;
  return (
    <Modal
      title="Delete chat?"
      isOpen={isModalOpen}
      onConfirm={async () => {
        const { error } = await onDelete();
        if (chatIdProp && chatId == chatIdProp) {
          if (source === "genie") router.push(getGenieOverviewUrl());
          if (source == "chat") router.push(getChatOverviewUrl());
        }
        if (!error) createAlert("success", "Chat deleted successfully");
      }}
      confirmColor="red"
      confirmLabel="Delete"
      onClose={onClose}
    >
      Deleting this chat will immediately and permanently remove it. This action
      cannot be undone.
    </Modal>
  );
};
